









import Vue from 'vue';

export default Vue.extend({
  name: 'CheckboxGroup',

  props: {
    // 列表示 (縦表示) するかどうか
    column: {
      type: Boolean,
      // VuetifyのVRadioGroupと動作を合わせるために必要
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
    // 一度に表示するエラーの数
    errorCount: {
      type: [Number, String],
      default: 1,
    },
    // カスタムエラーメッセージ
    errorMessages: {
      type: [String, Array],
      default() {
        return [];
      },
    },
    // チェックボックスグループのラベル
    label: {
      type: String,
      default: undefined,
    },
    // 行表示 (横表示) するかどうか
    row: {
      type: Boolean,
    },
  },

  computed: {
    classesLegend(): { [key: string]: boolean } {
      return {
        'error--text': this.errorMessages.length > 0,
        'o-checkbox-group__legend': true,
        'theme--light': true,
        'v-label': true,
      };
    },
    classesVInput(): { [key: string]: boolean } {
      return {
        'o-checkbox-group': true,
        'o-checkbox-group--column': this.column && !this.row,
        'o-checkbox-group--row': this.row,
        'v-input--selection-controls': true,
      };
    },
  },
});
