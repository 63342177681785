
















































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';

// itemスロットによって提供される関連データ
interface ItemSlotData {
  expand: (value: boolean) => void;
  headers: DataTableHeader[];
  isExpanded: boolean;
  isMobile: boolean;
  isSelected: boolean;
  // Vuetifyの型定義に合わせるため除外
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  select: (value: boolean) => void;
}

export default Vue.extend({
  name: 'DataTable',

  inheritAttrs: false,

  props: {
    // 行クリックで追加行を開くかどうか
    expandByClick: {
      type: Boolean,
    },
    // 開いている追加行の行データの配列 (初期値及び親からの変更通知用)
    expanded: {
      // Vuetifyの型定義に合わせるため除外
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<any[]>,
      default() {
        return [];
      },
    },
    // ヘッダー
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      default() {
        return [];
      },
    },
    // 各行データでユニークキーとして使われるプロパティ
    itemKey: {
      type: String,
      default: 'id',
    },
    // 追加行を開閉するための列を表示するかどうか
    showExpand: {
      type: Boolean,
    },
    // 一度に開ける追加行を一つだけにするかどうか
    singleExpand: {
      type: Boolean,
    },
    // ストライプ表示をするかどうか
    stripe: {
      type: Boolean,
    },
  },

  data(): {
    // 開いている追加行の行データの配列 (コンポーネント内の制御用)
    // Vuetifyの型定義に合わせるため除外
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    expandedInComponent: any[];
  } {
    return {
      expandedInComponent: [],
    };
  },

  computed: {
    // VDataTableに追加するクラス
    classes(): string[] {
      const classes = ['o-data-table'];
      if (this.stripe) {
        classes.push('o-data-table--stripe');
      }

      return classes;
    },
    // item.<name>スロットの名前
    itemSlotNames(): string[] {
      return this._.reduce(
        this.headers,
        (result: string[], header: DataTableHeader) => {
          if (header.value !== 'data-table-expand') {
            result.push(`item.${header.value}`);
          }

          return result;
        },
        []
      );
    },
  },

  watch: {
    // 開いている追加行の行データの配列が親で変更された場合、それをコンポーネント内に反映する
    expanded: {
      immediate: true,
      handler(value) {
        this.expandedInComponent = value;
      },
    },
  },

  methods: {
    // 行がクリックされたことを親に通知する
    // itemData: クリックされた行の行データ
    // itemSlotData: itemスロットによって提供される関連データ
    // Vuetifyの型定義に合わせるため除外
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleClickRow(itemData: any, itemSlotData: ItemSlotData) {
      this.$emit('click:row', itemData, itemSlotData);

      // TODO: 処理をVDataTableと合わせる
      // 複数の追加行表示可能な場合に、expandedの順序が異なるせいでupdate:expandedが複数通知されてしまう
      if (this.expandByClick) {
        if (this.singleExpand) {
          if (this.expandedInComponent.includes(itemData)) {
            // コンポーネント内の値を更新すると共に、親に更新を通知するためにイベントを発行する
            this.expandedInComponent.pop();
            this.$emit('item-expanded', itemData, false);
            this.$emit('update:expanded', this.expandedInComponent);
          } else {
            // コンポーネント内の値を更新すると共に、親に更新を通知するためにイベントを発行する
            this.$set(this.expandedInComponent, 0, itemData);
            this.$emit('item-expanded', itemData, true);
            this.$emit('update:expanded', this.expandedInComponent);
          }
        } else if (this.expandedInComponent.includes(itemData)) {
          const index = this.expandedInComponent.indexOf(itemData);

          this.expandedInComponent.splice(index, 1);
          this.$emit('item-expanded', itemData, false);
          this.$emit('update:expanded', this.expandedInComponent);
        } else {
          this.expandedInComponent.push(itemData);
          this.$emit('item-expanded', itemData, true);
          this.$emit('update:expanded', this.expandedInComponent);
        }
      }
    },

    // 行が右クリックされたことを親に通知する
    // event: マウスイベント
    // itemSlotData: itemスロットによって提供される関連データ
    handleContextmenuRow(event: MouseEvent, itemSlotData: ItemSlotData) {
      this.$emit('contextmenu:row', event, itemSlotData);
    },

    // (Vuetifyのドキュメントに記載なし) itemsが変更されたことを親に通知する？
    // items: 行データの配列
    // Vuetifyの型定義に合わせるため除外
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleCurrentItems(items: any[]) {
      this.$emit('current-items', items);
    },

    // 行がダブルクリックされたことを親に通知する
    // event: マウスイベント
    // itemSlotData: itemスロットによって提供される関連データ
    handleDblclickRow(event: MouseEvent, itemSlotData: ItemSlotData) {
      this.$emit('dblclick:row', event, itemSlotData);
    },

    // 入力を親に通知する (チェックボックスの選択/非選択切り替え時に発生)
    // input: 選択中の行の行データの配列
    // Vuetifyの型定義に合わせるため除外
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleInput(input: any[]) {
      this.$emit('input', input);
    },

    // 追加行の表示/非表示切替発生を親に通知する
    // expandInfo: 追加行情報
    //   .item: 追加行の行データ
    //   .value: 表示した場合はtrue, 非表示にした場合はfalse
    // Vuetifyの型定義に合わせるため除外
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleItemExpanded(expandInfo: { item: any; value: boolean }) {
      this.$emit('item-expanded', expandInfo);
    },

    // 行の選択/非選択切り替え発生を親に通知する
    // selectInfo: 選択/非選択行情報
    //   .item: 選択/非選択行の行データ
    //   .value: 選択した場合はtrue, 非選択にした場合はfalse
    // Vuetifyの型定義に合わせるため除外
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleItemSelected(selectInfo: { item: any; value: boolean }) {
      this.$emit('item-selected', selectInfo);
    },

    // (Vuetifyのドキュメントに記載なし) ページ数が変更されたことを親に通知する？
    // value: ページ数
    handlePageCount(value: number) {
      this.$emit('page-count', value);
    },

    // (Vuetifyのドキュメントに記載なし) ページネーション系情報が変更されたことを親に通知する？
    // pageInfo: ページネーション系情報
    //  .page: 現在のページ
    //  .itemPerPage: ページごとの表示件数
    //  .pageStart: ?
    //  .pageStop: ?
    //  .pageCount: ページ数
    //  .itemsLength: ?
    handlePagination(pageInfo: {
      page: number;
      itemsPerPage: number;
      pageStart: number;
      pageStop: number;
      pageCount: number;
      itemsLength: number;
    }) {
      this.$emit('pagination', pageInfo);
    },

    // 行の全選択/全非選択発生を親に通知する
    // selectInfo: 全選択/全非選択行情報
    //   .items: 全選択/全非選択行の行データの配列
    //   .value: 全選択の場合はtrue, 全非選択の場合はfalse
    // Vuetifyの型定義に合わせるため除外
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleToggleSelectAll(selectInfo: { items: any[]; value: boolean }) {
      this.$emit('toggle-select-all', selectInfo);
    },

    // 開いている追加行の行データの配列の変更を親に通知する
    // items: 開いている追加行の行データの配列
    // Vuetifyの型定義に合わせるため除外
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleUpdateExpanded(expanded: any[]) {
      if (!this._.isEqual(this.expandedInComponent, expanded)) {
        // コンポーネント内の値を更新すると共に、親に更新を通知するためにイベントを発行する
        this.expandedInComponent = expanded;
        this.$emit('update:expanded', expanded);
      }
    },

    // 行データのグループ化に使用するプロパティの変更を親に通知する
    // groupBy: 行データのグループ化に使用するプロパティ
    handleUpdateGroupBy(groupBy: string | string[]) {
      this.$emit('update:group-by', groupBy);
    },

    // 行データのグループ化の順序の変更を親に通知する
    // groupDesc: 行データのグループ化の順序 (true: 降順, false:昇順)
    handleUpdateGroupDesc(groupDesc: boolean | boolean[]) {
      this.$emit('update:group-desc', groupDesc);
    },

    // ページごとの表示件数の変更を親に通知する
    // itemsPerPage: ページごとの表示件数
    handleUpdateItemsPerPage(itemsPerPage: number) {
      this.$emit('update:items-per-page', itemsPerPage);
    },

    // 複数のプロパティによるソートを有効にするかどうかの変更を親に通知する
    // multiSort: 複数のプロパティによるソートを有効にするかどうか
    handleUpdateMultiSort(multiSort: boolean) {
      this.$emit('update:multi-sort', multiSort);
    },

    // ソートを必須にするかどうかの変更を親に通知する
    // mustSort: ソートを必須にするかどうか
    handleUpdateMustSort(mustSort: boolean) {
      this.$emit('update:must-sort', mustSort);
    },

    // データテーブルオプションの変更を親に通知する
    // options: データテーブルオプション
    //   .page: 現在のページ
    //   .itemsPerPage: ページごとの表示件数
    //   .sortBy: ソートに使用するプロパティ
    //   .sortDesc: ソート順 (true: 降順, false:昇順)
    //   .groupBy: 行データのグループ化に使用するプロパティ
    //   .groupDesc: 行データのグループ化の順序 (true: 降順, false:昇順)
    //   .multiSort: 複数のプロパティによるソートを有効にするかどうか
    //   .mustSort: ソートを必須にするかどうか
    handleUpdateOptions(options: {
      page: number;
      itemsPerPage: number;
      sortBy: string[];
      sortDesc: boolean[];
      groupBy: string[];
      groupDesc: boolean[];
      multiSort: boolean;
      mustSort: boolean;
    }) {
      this.$emit('update:options', options);
    },

    // 現在のページの変更を親に通知する
    // page: 現在のページ
    handleUpdatePage(page: number) {
      this.$emit('update:page', page);
    },

    // ソートに使用するプロパティの変更を親に通知する
    // sortBy: ソートに使用するプロパティ
    handleUpdateSortBy(sortBy: string | string[]) {
      this.$emit('update:sort-by', sortBy);
    },

    // ソート順の変更を親に通知する
    // sortDesc: ソート順 (true: 降順, false:昇順)
    handleUpdateSortDesc(sortDesc: boolean | boolean[]) {
      this.$emit('update:sort-desc', sortDesc);
    },
  },
});
