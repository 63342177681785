<template>
  <h1 class="text-h5 d-flex align-center mb-3">
    <VIcon large class="mr-1">{{ icon }}</VIcon>
    {{ title }}
  </h1>
</template>

<script>
export default {
  name: 'PageTitle',

  props: {
    // ページタイトルの先頭に付けるアイコン
    icon: {
      type: String,
      required: true,
    },
    // ページタイトル
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
